<template>
  <div>
    <b-row>
      <b-col class="login-button mb-2" cols="12">
        <router-link :to="{name: 'auth-login'}">
          {{ $t('Go to Login Page') }}
        </router-link>
      </b-col>
      <b-col class="profile-image">
        <router-link :to="{name: 'auth-login'}">
          <img v-if="screenWidth > 768" :src="profilesImage" />
          <img v-else :src="profilesMobileImage" />
        </router-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UserList from '@/components/UserList'

export default {
  name: 'Index',
  components: {
    UserList,
  },
  data () {
    return {
      profilesImage: require('@/assets/images/bg/profiles.png'),
      profilesMobileImage: require('@/assets/images/bg/profiles-mobile.png'),
      screenWidth: 0,
    }
  },
  mounted() {
    this.updateScreenWidth();
    this.onScreenResize();
  },
  methods: {
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  }
}
</script>

<style lang="scss" scoped>
.login-button {
  display: flex;
  justify-content: center;

  a {
    font-size: 24px !important;
    text-decoration: underline;
  }
}
.profile-image {
  img {
    width: 100% !important;
    height: auto !important;
  }
}
</style>